html {
  scroll-behavior: smooth !important;
}

/* .fancybox-image, .fancybox-spaceball {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;
  max-width: none;
  max-height: none;
} */

/* .swiper-wrapper {



  display: flex !important;
  height: 100%;
  position: relative;

  width: 100%;
  z-index: 1
} */

::-webkit-scrollbar {
  width: 3px;
  border-radius: 2px;
  transition: all 5s linear !important;
  border: 1px solid transparent !important;

}

::-webkit-scrollbar:hover {
  width: 10px;


}

::-webkit-scrollbar-thumb {
  border-radius: 0px;
  /* background-color: #242c7d; */
  background-color: #d8b560;
  border: 0 !important;

}

/* Main [data-cue="zoomIn"], [data-cues="zoomIn"]>* {
   opacity: 1;
 }

 ::-webkit-scrollbar {
   width: 2px;
   border-radius: 5px;
   transition: all 5s linear !important;
   border: 0 !important;
 }

 ::-webkit-scrollbar:hover {
   width: 10px;


 }

 ::-webkit-scrollbar-thumb {
   border-radius: 2px;
   background-color: #0A254C;
   border: 0 !important;

 }

 .dark::-webkit-scrollbar-thumb {
   border-radius: 2px;
   background-color: #D9B95E;
   border: 0 !important;

 }

 ::-webkit-scrollbar-track {
   background-color: transparent;

 }

 .curser-pointer {
   cursor: pointer;
 }



 @media screen and (min-width:767px) {
   .home-five p.section-desc2 {
     width: 50% !important;
   }
 }

 .text-main {
   color: #0A254C;
 } */
.splide__arrow svg {
  fill: #d8b560 !important;
}

.splide__arrow {
  background: #fff !important;
  box-shadow: 0 0 2px #666;
}

li::marker {
  color: #d8b560;
}






/* scroll bar for modal  */
.modal::-webkit-scrollbar {
  width: 50px !important;
}

.modal::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.modal::-webkit-scrollbar-thumb {
  background: #d8b560 !important;
  border-radius: 20px !important;
}

.modal::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

/* For Firefox */
.modal {
  scrollbar-width: thin !important;
  scrollbar-color: #d8b560 #ccc !important;
}

/* snack  */
.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomLeft.css-cpgvjg-MuiSnackbar-root .css-xsgba7-MuiPaper-root-MuiAlert-root, .css-1e01h8j {
  background-color: #d8b560;
  color: #fff;
}