* {
	box-sizing: border-box;
}

body {
	padding: 0;
	margin: 0;
}

.google {
	opacity: 1 !important;
	display: flex;
	justify-content: center;
}



.react-datepicker__day--keyboard-selected {
	/* border-radius: 0.3rem; */
	/* background-color: #bad9f1; */
	/* background-color: transparent !important; */
}

/* 
.fancybox-slide::before {
	content: 'x';
	display: inline-block;
	vertical-align: middle;
	height: 100%;
	width: 30px;
	height: 30px;
	position: absolute;
	background-color: #d8b560;
	color: #fff;
	right: 18vw;
	top: 4vh;
	z-index: 1;
	border-radius: 50%;
	cursor: pointer;
}
 */
.video-icon {
	position: relative;
}

.video-icon i {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}



/* DatePicker  */
.react-datepicker__header, .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
	background-color: #d8b560 !important;
	border-radius: 0 !important;
	font-size: 16px !important;
}

.react-datepicker__header, .react-datepicker__current-month {

	background: #977A42 !important;
	background: #d8b560 !important;
	color: #fff !important;
	padding: 5px 0 10px 0;

}

.react-datepicker__week .react-datepicker__day--disabled {
	text-decoration: line-through;
}

.react-datepicker__day:hover {
	border-radius: 0 !important;
}

.react-datepicker__day {

	font-size: 14px !important;
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
	background: #977A42 !important;

}

.react-datepicker__navigation {
	height: 42px !important;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
	border-color: #fff !important;
	color: #fff !important;
}

.react-datepicker__day-name, .react-datepicker__day-names {
	color: #fff !important;
	font-size: 13px !important;
	font-weight: bold !important;
	background: #977A42 !important;
	padding: 2px 0 !important;
}


#pills-five-example5 .react-datepicker__day, #pills-five-example5 .react-datepicker__month-text, #pills-five-example5 .react-datepicker__quarter-text, #pills-five-example5 .react-datepicker__year-text {
	background-color: #e7e5e5;

}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
	background-color: #d8b560 !important;
	color: #fff !important;
}

#pills-five-example5 .react-datepicker__day--disabled, #pills-five-example5 .react-datepicker__month-text--disabled, #pills-five-example5 .react-datepicker__quarter-text--disabled, #pills-five-example5 .react-datepicker__year-text--disabled {
	background-color: #edf0f5 !important;
}

.react-datepicker__week .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
	cursor: not-allowed;
	background-color: unset !important;
	color: #abaaaa !important;
}

button.owl-prev:hover span, button.owl-next:hover span {
	color: #000 !important;
}

.react-datepicker-wrapper {
	width: 100% !important;
}

@media screen and (max-width:767px) {
	.gallery-section .owl-nav {
		top: -5vh !important;
		padding: 20px;
	}

	.gallery-section .owl-nav .owl-prev {
		right: 11vw !important;
		font-size: 30px !important;
	}

	.gallery-section .owl-nav .owl-next {
		right: 0vw !important;
		font-size: 30px !important;
	}

}

.languages li:hover {
	background-color: #ececec;
}

.languages li:hover a {
	color: #d8b560 !important;
}

.room-block-four .image-box .immmm {
	transform: rotateZ(50deg);
}